import { IncomingMessage } from 'http';
import querystring from 'query-string';
import { ParsedUrlQuery } from 'querystring';
import { parse } from 'url';

import { Prices } from '@/store/prices/types';
import apiRequest, { userDataFromRequest } from '@/utils/apiRequest';
import { calmLogger } from '@/utils/calmLogger';
import getParamAsString from '@/utils/getParamsAsString';

import { mergeCatalogPriceIntoPrices } from '../../utils/prices/mergeCatalogPriceIntoPrices';
import { httpAgent, httpsAgent } from '../utils/httpAgent';
import { getFamilyPrices } from './getFamilyPrices';

const getCouponFromQuery = (query: ParsedUrlQuery) => {
	return query.coupon && typeof query.coupon !== 'object' ? getParamAsString(query.coupon) : null;
};

export const createPricesEndpoint = (query: ParsedUrlQuery, coupon?: string | string[] | null): string => {
	const pricesQueryParams = querystring.stringify({
		...query,
		...(typeof coupon === 'string' ? { coupon } : {}),
	});

	return `subscription/prices?${pricesQueryParams}`;
};

export async function getPrices(
	req: IncomingMessage,
	deviceId: string,
	calmIdentifier: string | undefined,
	couponFromState?: string | null,
): Promise<Prices | null> {
	const { query } = parse(req.url as string, true);

	let sanitizedQuery = { ...query };
	if (query.guestPass && query.coupon) {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { coupon, ...rest } = query;
		sanitizedQuery = { ...rest };
	}

	const coupon = couponFromState ?? getCouponFromQuery(sanitizedQuery);
	const pricesEndpoint = createPricesEndpoint(sanitizedQuery, coupon);

	try {
		const [subPrices, familyPrice] = await Promise.all([
			apiRequest<Prices>({
				endpoint: pricesEndpoint,
				method: 'GET',
				userData: userDataFromRequest({ req, deviceId, calmIdentifier }),
				agents: { httpAgent, httpsAgent },
			}),
			getFamilyPrices(req, deviceId, calmIdentifier),
		]);

		const shapedData = familyPrice
			? mergeCatalogPriceIntoPrices(familyPrice, subPrices.data, 'family')
			: subPrices.data;
		return shapedData;
	} catch (err) {
		calmLogger.error(`Error retrieving prices via GET /${pricesEndpoint}`, {}, err);
		return null;
	}
}
