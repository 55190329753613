import SHA256 from 'crypto-js/sha256';
import uuid from 'uuid/v4';

import { ProductInfo } from '@/hooks/analytics/types';
import { formatCurrency } from '@/hooks/purchase/useFormattedCurrency';
import { getCouponToSend } from '@/hooks/purchase/useSubPurchase';
import { FAMILY_PLAN_YEARLY_PLAN_ID } from '@/server/initState/getFamilyPrices';
import { Prices } from '@/store/prices/types';
import { PurchaseParams, isValidPlan } from '@/store/purchaseParams/types';
import { User } from '@/store/user/types';
import { userCanTrial } from '@/utils/subscriptions';

export const createSuccessEventProps = ({
	productInfo,
	prices,
	purchaseParams,
	userEmail,
	selectedPaymentType,
	paymentFormCurrency,
	successArgs,
	browserLanguage,
}: {
	productInfo: ProductInfo;
	prices: Prices;
	purchaseParams: PurchaseParams;
	selectedPaymentType: string | null;
	paymentFormCurrency: string;
	browserLanguage: string | null;
	userEmail?: string;
	successArgs?: Record<string, unknown>;
}) => {
	return {
		name: productInfo.name,
		unit_price: productInfo.unit_price,
		quantity: productInfo.quantity,
		plan: productInfo.sku,
		promotion: purchaseParams?.promotion ?? null,
		amp_order_id: uuid(),
		hashed_email: userEmail ? SHA256(userEmail).toString() : null,
		currency: paymentFormCurrency,
		local_price: formatCurrency(prices.current[purchaseParams.plan], paymentFormCurrency, browserLanguage),
		is_free_trial: purchaseParams?.purchaseType?.type === 'freetrial',
		isFreeTrial: purchaseParams?.purchaseType?.type === 'freetrial',
		payment_type: selectedPaymentType,
		...successArgs,
	};
};

export const createPurchaseBodyArgs = ({
	purchaseParams,
	user,
	prices,
	purchaseArgs,
}: {
	purchaseParams: PurchaseParams;
	user: User | null;
	prices: Prices;
	purchaseArgs?: Record<string, unknown>;
}) => {
	const { plan } = purchaseParams;
	const _plan = purchaseArgs?.plan && typeof purchaseArgs.plan === 'string' ? purchaseArgs.plan : plan;
	const isNonFreeTrialCoupon = purchaseParams.coupon && purchaseParams.coupon !== 'freetrial';
	const meetsTrialRequirements =
		userCanTrial(user) && // user can trial
		(plan !== 'monthly' || // plan is not monthly
			!!purchaseArgs?.partner_id) && // plan is monthly but has a partner_id
		!isNonFreeTrialCoupon; // coupon is not a non-free trial coupon
	const isStandardPlan = isValidPlan(_plan);

	return {
		...purchaseParams,
		plan: _plan === 'family' ? FAMILY_PLAN_YEARLY_PLAN_ID : _plan,
		email: user?.email,
		name: user?.name,
		coupon: getCouponToSend(purchaseParams.coupon),
		freeTrial:
			(purchaseParams.coupon === 'freetrial' || purchaseParams.purchaseType?.isFreeTrial) &&
			meetsTrialRequirements,
		purchaseType: isStandardPlan
			? {
					...purchaseParams.purchaseType,
					type: meetsTrialRequirements ? 'freetrial' : _plan,
					price: prices?.current[_plan] || 0,
					duration: purchaseParams?.purchaseType?.duration ?? 14,
			  }
			: undefined,
		...purchaseArgs,
	};
};
