/* eslint-disable no-nested-ternary */
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { FontSizes, FontWeights, Text } from '@calm-web/design-system';

import { trialDurationMap } from '@/components/dtcPartnerships/shared/types';
import { useFormattedCurrency } from '@/hooks/purchase';
import { useFormattedPriceWithTax } from '@/hooks/purchase/useFormattedPriceWithTax';
import { usePartnerState, usePricesState, usePurchaseParamsState } from '@/hooks/store';
import genericMessages from '@/messages/messages';
import { PlanCadence } from '@/store/purchaseParams/types';

import { CalendarUnits } from '../utils/Purchase';
import messages from './messages';
import {
	Capitalize,
	Divider,
	OfferHighlight,
	OrderRowLight,
	OrderRowSmallLight,
	OrderRowBold,
	Wrapper,
} from './styles';

interface PurchaseDetailsInnerProps {
	originalPrice: number;
	currentPrice: number;
	isFreeTrial: boolean;
	isShareContent?: boolean;
	isExtendedTrial?: boolean;
	plan: PlanCadence;
	showCostPerYear?: boolean;
	isSecondFreeTrial?: boolean;
	amountDue?: number;
	percentOff?: number | null;
	fixedDiscount?: number | null;
	trialUnit?: string | null;
	trialDuration?: number;
	parsedTrialLength?: string;
	addedPartnerBranding?: string | null;
	isRenewalOffer?: boolean;
}

interface planTypeDisplayType {
	abbreviation?: string;
	title: string;
}

interface planMapType {
	monthly: planTypeDisplayType;
	quarterly: planTypeDisplayType;
	yearly: planTypeDisplayType;
	lifetime: planTypeDisplayType;
	family: planTypeDisplayType;
}

export const PurchaseDetailsInner = ({
	amountDue,
	originalPrice,
	currentPrice,
	isFreeTrial,
	isShareContent,
	parsedTrialLength,
	plan,
	percentOff,
	fixedDiscount,
	trialUnit,
	trialDuration,
	addedPartnerBranding,
	isRenewalOffer,
}: PurchaseDetailsInnerProps) => {
	const { formatMessage } = useIntl();
	const formatCurrency = useFormattedCurrency();

	const currentPriceWithTax = useFormattedPriceWithTax(currentPrice);

	const totalDueToday = !isFreeTrial && !isRenewalOffer ? currentPrice : 0;

	const totalDueTodayWithTax = useFormattedPriceWithTax(amountDue || 0);

	const planMap: planMapType = {
		yearly: {
			abbreviation: formatMessage(messages.yearlyAbbreviation),
			title: formatMessage(messages.yearlyTitle),
		},
		quarterly: {
			abbreviation: formatMessage(messages.every3mo),
			title: formatMessage(messages.quarterlyTitle),
		},
		monthly: {
			abbreviation: formatMessage(messages.monthlyAbbreviation),
			title: formatMessage(messages.monthlyTitle),
		},
		lifetime: {
			title: formatMessage(messages.lifetimeTitle),
		},
		family: {
			abbreviation: formatMessage(messages.yearlyAbbreviation),
			title: formatMessage(messages.familyTitle),
		},
	};

	const planDetails = planMap[plan || 'yearly'];

	const isShareTrialOffer = isShareContent && isFreeTrial;

	return (
		<Wrapper>
			<>
				<OrderRowLight>
					{isRenewalOffer ? (
						<>
							<div>{formatMessage(messages.currentRenewalPrice)}</div>
							<div>
								{formatCurrency(originalPrice)}
								{planDetails.abbreviation && `/${planDetails.abbreviation}`}
							</div>
						</>
					) : isShareTrialOffer ? (
						<>
							<Capitalize>{formatMessage(messages.monthlyBreakdown)}*</Capitalize>
							<div>{formatCurrency(originalPrice / 12)}</div>
						</>
					) : (
						<>
							<Capitalize>{planDetails.title}</Capitalize>
							<div>
								{currentPrice !== originalPrice ? (
									<s>{formatCurrency(originalPrice)}</s>
								) : (
									formatCurrency(originalPrice)
								)}
								{planDetails.abbreviation && `/${planDetails.abbreviation}`}
							</div>
						</>
					)}
				</OrderRowLight>
				{parsedTrialLength && (
					<OfferHighlight>
						<OrderRowLight>
							{parsedTrialLength ??
								formatMessage(messages.trialDuration, {
									duration: trialDuration,
									unit: trialUnit,
								})}
							{}
							<div>{formatMessage(genericMessages.free).toUpperCase()}</div>
						</OrderRowLight>
					</OfferHighlight>
				)}
				{percentOff && (
					<OfferHighlight>
						<OrderRowLight>
							{percentOff}% {addedPartnerBranding ?? formatMessage(messages.discount)}
							<div>{formatCurrency(currentPrice - originalPrice)}</div>
						</OrderRowLight>
					</OfferHighlight>
				)}
				{fixedDiscount && currentPrice !== originalPrice && (
					<OfferHighlight>
						<OrderRowLight>
							<div>{formatMessage(messages.discount)}</div>
							<div>{formatCurrency(currentPrice - originalPrice)}</div>
						</OrderRowLight>
					</OfferHighlight>
				)}
				<Divider />
			</>
			{totalDueToday === 0 && isRenewalOffer && (
				<OrderRowBold>
					{formatMessage(messages.totalDueAtRenewal)}
					<div>{currentPriceWithTax}</div>
				</OrderRowBold>
			)}
			<OrderRowBold>
				<div>
					{formatMessage(messages.totalDueToday)}
					{!isShareContent && !isRenewalOffer && <span>*</span>}
				</div>
				<div>{totalDueToday === 0 ? formatCurrency(0) : totalDueTodayWithTax}</div>
			</OrderRowBold>
			{totalDueToday === 0 && !isShareContent && !isRenewalOffer && (
				<OrderRowLight>
					{formatMessage(messages.totalDueAfterTrial)}
					<div>{currentPriceWithTax}</div>
				</OrderRowLight>
			)}
			<OrderRowSmallLight align="left" size={FontSizes.sm} color="gray5" weight={FontWeights.Medium}>
				<Text size={FontSizes.sm}>
					{isShareTrialOffer && <span>*</span>}
					{isFreeTrial &&
						formatMessage(messages.trialEndTerms, {
							cost: formatCurrency(originalPrice),
						})}
				</Text>
			</OrderRowSmallLight>
		</Wrapper>
	);
};

const PurchaseDetails = ({
	showCostPerYear,
	isSecondFreeTrial,
	isShareContent,
	isRenewalOffer,
}: {
	showCostPerYear?: boolean;
	isSecondFreeTrial?: boolean;
	isShareContent?: boolean;
	isRenewalOffer?: boolean;
}) => {
	const prices = usePricesState();
	const purchaseParams = usePurchaseParamsState();
	const { formatMessage } = useIntl();
	const { plan, purchaseType } = purchaseParams;

	const [isFreeTrial, setIsFreeTrial] = useState<boolean>(true);

	useEffect(() => {
		setIsFreeTrial(purchaseType?.type === 'freetrial');
	}, [purchaseType]);

	const partner = usePartnerState();
	const addedPartnerBranding =
		partner?.name && partner?.name.toLowerCase() === 'aarp'
			? formatMessage(messages.aarpPartnerBranding, {
					partner: partner?.name,
			  })
			: null;

	const trialLength = useCallback(() => {
		if (partner && partner?.offer_details?.trial_duration) {
			const trial = trialDurationMap[partner?.offer_details?.trial_duration];
			return `${
				trial.unit + 's' === CalendarUnits.MONTHS
					? formatMessage(messages.trialMonths, {
							number: trial.duration,
					  })
					: formatMessage(messages.trialDays, {
							number: trial.duration,
					  })
			} ${formatMessage(messages.trial)}`;
		}
		if (purchaseType?.duration && isFreeTrial) {
			return `${
				purchaseType?.units + 's' === CalendarUnits.MONTHS
					? formatMessage(messages.trialMonths, {
							number: purchaseType?.duration,
					  })
					: formatMessage(messages.trialDays, {
							number: purchaseType?.duration,
					  })
			} ${formatMessage(messages.trial)}`;
		}
	}, [purchaseType, isFreeTrial, formatMessage, partner]);

	return (
		<PurchaseDetailsInner
			amountDue={isFreeTrial || isRenewalOffer ? 0 : prices.current[plan]}
			isSecondFreeTrial={isSecondFreeTrial}
			isShareContent={isShareContent}
			plan={plan}
			originalPrice={prices.original[plan]}
			currentPrice={prices.current[plan]}
			isFreeTrial={isFreeTrial}
			showCostPerYear={showCostPerYear}
			percentOff={purchaseParams.percentOff || prices.coupons[plan]?.percent_off}
			fixedDiscount={purchaseParams.fixedDiscount || prices.coupons[plan]?.amount_off}
			trialDuration={purchaseType?.duration}
			trialUnit={purchaseType?.units}
			parsedTrialLength={trialLength()}
			addedPartnerBranding={addedPartnerBranding}
			isRenewalOffer={isRenewalOffer}
		/>
	);
};

export default PurchaseDetails;
