import { ParsedUrlQuery } from 'querystring';

import { getFamilyPrices } from '@/server/initState/getFamilyPrices';
import { createPricesEndpoint } from '@/server/initState/getPrices';
import { Prices } from '@/store/prices/types';
import apiRequest from '@/utils/apiRequest';
import { mergeCatalogPriceIntoPrices } from '@/utils/prices/mergeCatalogPriceIntoPrices';

import { calmLogger } from '../calmLogger';

export async function getPrices(query: ParsedUrlQuery): Promise<Prices | null> {
	let sanitizedQuery = { ...query };
	if (query.guestPass && query.coupon) {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { coupon, lifetime_coupon, ...rest } = query;
		sanitizedQuery = { ...rest };
	}

	const endpoint = createPricesEndpoint(sanitizedQuery, sanitizedQuery.coupon);

	try {
		const [subPrices, familyPrice] = await Promise.all([
			apiRequest<Prices>({
				endpoint,
				method: 'GET',
			}),
			getFamilyPrices(),
		]);

		const shapedData = familyPrice
			? mergeCatalogPriceIntoPrices(familyPrice, subPrices.data, 'family')
			: subPrices.data;

		return shapedData;
	} catch (err) {
		calmLogger.error(`Error retrieving prices via GET /${endpoint}`, {}, err);
		return null;
	}
}
