import { MessageDescriptor, useIntl } from 'react-intl';

import { FontSizes, FontSize, FontWeight, FontWeights, PaletteColor } from '@calm-web/design-system';

import {
	BulletPoint,
	CheckIcon,
	List,
	Unordered,
	ValuePropTextContainer,
	ValuePropBody,
	ValuePropHeader,
	Title,
	SubTitle,
	CircleIcon,
} from './styles';

export type ValuePropsListProps = {
	title?: string | JSX.Element | (string | JSX.Element)[] | null;
	subTitle?: string | JSX.Element | (string | JSX.Element)[] | null;
	copy?: Array<CopyItem>;
	buttonColor: PaletteColor;
	textColor?: PaletteColor;
	fontWeight?: FontWeight;
	headerSize?: FontSize;
	propSize?: FontSize;
	propPadding?: boolean;
	gapSize?: string;
	titleSize?: FontSize;
	titleColor?: PaletteColor;
	className?: string;
	isRevampRedempFlow?: boolean;
	isB2BAccountPage?: boolean;
	isOrdered?: boolean;
};

export type CopyItem = {
	header?: MessageDescriptor;
	body?: MessageDescriptor;
	id: string;
};

const ValuePropsList = ({
	buttonColor,
	fontWeight,
	textColor = 'black',
	title,
	subTitle,
	copy,
	headerSize = FontSizes.base,
	propSize = FontSizes.base,
	propPadding = false,
	gapSize,
	titleSize,
	titleColor,
	className,
	isRevampRedempFlow,
	isB2BAccountPage,
	isOrdered,
}: ValuePropsListProps) => {
	const { formatMessage } = useIntl();

	const getBulletIcon = (index: number) => {
		if (isOrdered) {
			return (
				<CircleIcon aria-hidden $color={buttonColor}>
					{index + 1}
				</CircleIcon>
			);
		}

		return <CheckIcon aria-hidden $color={buttonColor} />;
	};

	return (
		<List hasTitle={!!title} className={className} isRevampRedempFlow={isRevampRedempFlow}>
			{title && (
				<Title
					el={isB2BAccountPage ? 'h1' : 'h3'}
					color={titleColor || textColor}
					size={titleSize || FontSizes.lg}
					weight={fontWeight || FontWeights.Regular}
					align="left"
					isRevampRedempFlow={isRevampRedempFlow}
				>
					{title}
				</Title>
			)}
			{subTitle && <SubTitle>{subTitle}</SubTitle>}
			{copy && (
				<Unordered gapSize={gapSize} isB2BAccountPage={isB2BAccountPage}>
					{copy.map((item, index) => (
						<BulletPoint key={item.id} $textColor={textColor}>
							{getBulletIcon(index)}
							<ValuePropTextContainer propPadding={propPadding}>
								{item.header && (
									<ValuePropHeader el="p" size={headerSize} weight={FontWeights.Demi}>
										{formatMessage(item.header)}
									</ValuePropHeader>
								)}
								{item.body && (
									<ValuePropBody el="p" size={propSize}>
										{formatMessage(item.body)}
									</ValuePropBody>
								)}
							</ValuePropTextContainer>
						</BulletPoint>
					))}
				</Unordered>
			)}
		</List>
	);
};

export default ValuePropsList;
