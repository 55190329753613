import { PlanSkuItemProps } from '../PlanSkuItem';
import PlanSkuItemFamily from '../PlanSkuItemFamily';
import { PlanSkuListItem, Wrapper } from './styles';

const PlanSkuItemsFamily = ({
	skus,
	setActivePlan,
}: {
	skus: PlanSkuItemProps[];
	setActivePlan: (id: PlanSkuItemProps['id']) => void;
}) => {
	return (
		<Wrapper>
			{skus.map(sku => (
				<PlanSkuListItem key={sku.id}>
					<PlanSkuItemFamily
						duration={sku.duration}
						originalPrice={sku.originalPrice || null}
						price={sku.price}
						active={sku.active}
						onClick={setActivePlan}
						id={sku.id}
						subheading={sku.subheading}
						numPeople={sku.numPeople}
						badge={sku.badge}
						badgeBg={sku.badgeBg}
					/>
				</PlanSkuListItem>
			))}
		</Wrapper>
	);
};

export default PlanSkuItemsFamily;
