import { defineMessages } from 'react-intl';

export default defineMessages({
	monthlyAbbreviation: {
		id: 'paymentForm.month.abbreviation',
		defaultMessage: 'mo.',
		description: 'An abbreviation of the word month',
	},
	yearlyAbbreviation: {
		id: 'paymentForm.year.abbreviation',
		defaultMessage: 'yr.',
		description: 'An abbreviation of the word year',
	},
});
