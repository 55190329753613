import { Prices } from '@/store/prices/types';

export type PriceCatalogResponse = {
	currency: string;
	price: number;
	original_price?: number;
	original_tiered_price?: number;
};

const CURRENCIES_WITHOUT_FRACTIONS = ['jpy', 'clp'];

export const mergeCatalogPriceIntoPrices = (
	priceCatalog: PriceCatalogResponse,
	prices: Prices,
	priceKey: string,
) => {
	const price = CURRENCIES_WITHOUT_FRACTIONS.includes(priceCatalog.currency)
		? priceCatalog.price * 100
		: priceCatalog.price;

	return {
		...prices,
		original: {
			...prices.original,
			[priceKey]: price,
		},
		current: {
			...prices.current,
			[priceKey]: price,
		},
	};
};
