import styled from 'styled-components';

import { fontSize, FontSizes, units } from '@calm-web/design-system';

export const Wrapper = styled.div`
	${fontSize(FontSizes.base)};
	display: flex;
	justify-content: center;
	margin-top: ${units(2)};
`;
