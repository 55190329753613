import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { FontSizes, FontWeights, Text } from '@calm-web/design-system';

import { PreSignupFlowContext } from '@/components/PreSignupFlow/PreSignupProvider';
import Branding from '@/components/SimplifiedSignup/Components/Branding';
import { IntegrationType } from '@/components/dtcPartnerships/shared/types';
import PaymentElementsFormRefresh from '@/components/purchase/PaymentElementsFormRefresh';
import { PurchaseFormSectionWrapper } from '@/components/purchase/PaymentElementsFormRefresh/styles';
import { useStripeElements } from '@/components/purchase/StripeElementsProvider';
import { useAnalytics } from '@/hooks/analytics';
import { usePartnerState, usePricesState, usePurchaseParamsState, useUserState } from '@/hooks/store';
import { useIsMobile } from '@/hooks/ui/useIsMobile';
import { useComponentDidMount } from '@/hooks/utils/useComponentDidMount';
import { userCanTrial } from '@/utils/subscriptions';

import { ValuePropsListWrapper } from '../Account/styles';
import ContentBasedImage from '../Components/ContentBasedImage';
import PartnershipValidation from '../Components/PartnershipValidation';
import QuizResults from '../Components/QuizResults';
import { useSimplifiedSignupContext } from '../Context';
import PaymentPropsList from '../PaymentPropsList';
import { PageWrap } from '../styles';
import messages from './messages';
import {
	LimitedTimeCallout,
	PaymentWrapper,
	PurchaseFormWrapper,
	TwoColumnWrapper,
	ValuePropBackingText,
	LoggedInAs,
} from './styles';
import { PaymentScreenConfig } from './types';

const Payment = () => {
	const {
		activeScreenConfig,
		name,
		fauxAuth,
		coBranded,
		screens: {
			account: { backgroundKey },
		},
		logPageViewEvent,
		flowConfig,
	} = useSimplifiedSignupContext();
	const {
		limitedTimeOffer,
		heading,
		subHeading,
		showHeader = true,
		valuePropBacking,
		contentBasedImage,
		isWithinModal,
		brandingPosition = 'right',
		terms,
		allowChurned,
		hidePaypal,
		eventProps,
		endpoint,
		ctaCopy,
	} = activeScreenConfig as PaymentScreenConfig;
	const { skippedToEnd = false, ActiveScreen, quizResults = false } = useContext(PreSignupFlowContext);
	const { formatMessage } = useIntl();
	const { isStripeNextEnabled } = useStripeElements();
	const user = useUserState();
	const prices = usePricesState();
	const purchaseParams = usePurchaseParamsState();
	const canTrial = userCanTrial(user);
	const { logEvent } = useAnalytics();
	const partner = usePartnerState();
	const { query } = useRouter();
	const isOrganicTraffic = query?.utm_medium !== 'paid';
	const [isMobile] = useIsMobile();
	const [landingEventFired, setLandingEventFired] = useState(false);
	const { email } = query;

	const [isVerified, setIsVerified] = useState(partner?.integration_type === 'DTC_CODELESS' || false);
	const [partnerUserId, setPartnerUserId] = useState(
		partner?.integration_type === 'DTC_CODELESS' ? 'DTC_CODELESS' : '',
	);

	useComponentDidMount(() => {
		logPageViewEvent('payment');
	});

	const { plan, purchaseType } = purchaseParams;

	const { message, placeholders = { dayNumber: isOrganicTraffic ? 14 : 7 } } = heading({
		trialLength: purchaseType?.duration && canTrial ? purchaseType.duration : 0,
		discountPercent: flowConfig && flowConfig.discount ? flowConfig.discount * 100 : undefined,
		prices,
		purchaseParams,
	});

	useEffect(() => {
		if ((partner && isVerified) || !partner) {
			if (landingEventFired) return;
			logEvent({
				eventName: 'Subscribe : Purchase : Form : Landed',
				eventProps: {
					source: name,
					is_stripe_next: isStripeNextEnabled,
					includes_quiz_recommendation: quizResults,
					...eventProps,
				},
			});
			setLandingEventFired(true);
		}
	}, [partner, isVerified, isStripeNextEnabled, name, logEvent, quizResults, landingEventFired, eventProps]);

	const showContentBasedImage =
		contentBasedImage || (ActiveScreen && ActiveScreen.upsellImage && skippedToEnd && !quizResults);

	const getBrandingHeader = () => {
		if (showHeader) {
			return null;
		}

		return <Branding backgroundColor={backgroundKey} coBranded={coBranded} />;
	};

	const getEndpoint = (args: Record<string, unknown>) => {
		if (partner?.id && partner?.name.toLowerCase() !== 'aarp') {
			return `partnerships/${partner.id}/subscriptions`;
		}

		if (plan === 'family') {
			const endpointPaymentMethod = args.paymentMethod === 'paypal' ? 'braintree' : 'stripe';
			return `familyplan/create/${endpointPaymentMethod}`;
		}

		if (email) {
			return 'subscription/email';
		}

		if (endpoint) {
			return endpoint;
		}

		return 'subscription';
	};

	return (
		<PageWrap data-testid="PaymentScreen" isWithinModal={isWithinModal} isCompact={isWithinModal}>
			<TwoColumnWrapper isWithinModal={isWithinModal}>
				<ValuePropsListWrapper showHeader={showHeader} isWithinModal={isWithinModal}>
					{(brandingPosition === 'left' || isMobile) && getBrandingHeader()}
					{limitedTimeOffer && (canTrial || allowChurned) && (
						<LimitedTimeCallout>{formatMessage(messages.limitedTimeCallout)}</LimitedTimeCallout>
					)}
					{showContentBasedImage && (!isMobile || !isWithinModal) && (
						<ContentBasedImage {...contentBasedImage} />
					)}
					{!quizResults && (
						<Text el="h1" align="center" size={FontSizes['2xl']} weight={FontWeights.Medium}>
							{formatMessage(message, placeholders)}
						</Text>
					)}
					{subHeading && (
						<Text el="h2" align="center" size={FontSizes.lg} weight={FontWeights.Medium}>
							{formatMessage(subHeading.message, subHeading.placeholders)}
						</Text>
					)}
					{quizResults && <QuizResults />}
					<PaymentPropsList />
					{valuePropBacking && (
						<em>
							<ValuePropBackingText skippedToEnd={skippedToEnd} el="p" align="left" size={FontSizes.sm}>
								{valuePropBacking}
							</ValuePropBackingText>
						</em>
					)}
				</ValuePropsListWrapper>
				<PaymentWrapper $overflow={isWithinModal && !isMobile}>
					{brandingPosition === 'right' && !isMobile && getBrandingHeader()}
					{partner && !isVerified && (
						<PartnershipValidation
							isVerified={isVerified}
							setIsVerified={setIsVerified}
							setPartnerUserId={setPartnerUserId}
						/>
					)}
					{email && fauxAuth && (
						<PurchaseFormSectionWrapper>
							<LoggedInAs email={email as string} />
						</PurchaseFormSectionWrapper>
					)}
					{((partner && isVerified) || !partner) && (
						<PurchaseFormWrapper>
							<PaymentElementsFormRefresh
								endpoint={args => getEndpoint(args)}
								productInfo={{
									name: 'web_subscription',
									source: name,
									sku: plan,
									unit_price: purchaseType?.price ? prices.current[plan] / 100.0 : 0,
									quantity: 1,
								}}
								terms={terms}
								hidePaypal={hidePaypal}
								purchaseArgs={
									partner && partner.name.toLowerCase() !== 'aarp'
										? {
												partner_id: partner.id,
												...(partner.integration_type === IntegrationType.GROUP_CODE
													? { group_code: partnerUserId }
													: { partner_user_id: partnerUserId }),
										  }
										: email
										? { email }
										: {}
								}
								successArgs={
									partner
										? {
												partner_id: partner?.id,
												partner_type: [partner?.category?.toLowerCase() || null],
										  }
										: {}
								}
								ctaCopy={ctaCopy ? formatMessage(ctaCopy) : undefined}
							/>
						</PurchaseFormWrapper>
					)}
				</PaymentWrapper>
			</TwoColumnWrapper>
			<PaymentPropsList isForMobile />
		</PageWrap>
	);
};

export default Payment;
