import { Wrapper } from './styles';

const TrueMedWidget: React.FC = () => {
	return (
		<Wrapper>
			<div
				id="truemed-prequalify"
				icon-height="12"
				data-public-id={process.env.NEXT_PUBLIC_TRUEMED_PUBLIC_QUALIFICATION_ID}
			/>
		</Wrapper>
	);
};

export default TrueMedWidget;
