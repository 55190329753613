import { DateTime } from 'luxon';

import { Subscription, User } from '@/store/user/types';

export function userCanTrial(user: User | null): boolean {
	if (user?.subscription?.valid) {
		return false;
	}

	if (user?.subscription) {
		return false;
	}

	if (user?.subscription?.has_ever_done_free_trial) {
		return false;
	}
	return true;
}

export function isExpired(subscription?: Subscription): boolean {
	return !!subscription && !subscription.valid;
}

export function isTrialing(subscription?: Subscription): boolean {
	return !!subscription && subscription.stripe_details?.status === 'trialing';
}

export function shouldShowRenewal(subscription?: Subscription): boolean {
	if (!subscription) {
		return false;
	}
	if (subscription.will_renew) {
		return false;
	}
	if (!subscription.is_renewable) {
		return false;
	}

	const tenDaysFromNow = DateTime.fromJSDate(new Date()).plus({ days: 10 });
	const expiry = DateTime.fromISO(subscription.expires);
	if (expiry > tenDaysFromNow) {
		return false;
	}
	return true;
}

export function isB2BSubscriptionWithPartner(partnerId: string, subscription?: Subscription): boolean {
	return subscription?.type === 'b2b' && subscription?.b2b_details?.partner_id === partnerId;
}

// We don't allow you to redeem a B2B subscription if you have a valid, renewing subscription
export function hasSubscriptionConflictingWithB2BRedemption(subscription: Subscription | undefined): boolean {
	return Boolean(subscription?.valid && subscription?.type !== 'b2b' && subscription?.will_renew);
}

export function userCanSubscribe(user: User | null): boolean {
	if (!user) return true;
	if (user?.subscription?.is_lifetime) {
		return false;
	}
	if (user.subscription?.b2b_details) {
		// we don't have a way to subscribe over a b2b sub right now
		return false;
	}
	if (user.subscription?.valid && user.subscription?.will_renew) {
		return false;
	}
	return true;
}
