import { IncomingMessage } from 'http';

import apiRequest, { userDataFromRequest } from '@/utils/apiRequest';
import { calmLogger } from '@/utils/calmLogger';

import { PriceCatalogResponse } from '../../utils/prices/mergeCatalogPriceIntoPrices';
import { httpAgent, httpsAgent } from '../utils/httpAgent';

export const FAMILY_PLAN_YEARLY_PLAN_ID = 'family_plan_1y_autorenew';
const FAMILY_PRICING_ENDPOINT = `stripe-price-catalog/price?quantity=1&plan=${FAMILY_PLAN_YEARLY_PLAN_ID}`;

export async function getFamilyPrices(
	req?: IncomingMessage,
	deviceId?: string,
	calmIdentifier?: string | undefined,
): Promise<PriceCatalogResponse | null> {
	const isServer = req && deviceId;

	try {
		const response = await apiRequest<PriceCatalogResponse>({
			endpoint: FAMILY_PRICING_ENDPOINT,
			method: 'GET',
			userData: isServer ? userDataFromRequest({ req, deviceId, calmIdentifier }) : null,
			agents: isServer ? { httpAgent, httpsAgent } : null,
		});
		return response?.data || null;
	} catch (err) {
		calmLogger.error(`Error retrieving family prices via GET /${FAMILY_PRICING_ENDPOINT}`, {}, err);
		return null;
	}
}
