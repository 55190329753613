import styled from 'styled-components';
import { palette } from 'styled-tools';

import { CalmTheme, ChipButton, minWidth } from '@calm-web/design-system';

export const Wrapper = styled(ChipButton)`
	&&& {
		height: 100px;
		margin-bottom: 24px;
		text-align: left;
		width: 100%;
		position: relative;

		${minWidth('desktop')} {
			padding: 0 24px;
		}

		&:focus {
			&:before {
				opacity: 0;
			}
		}
	}
`;

export const PlanSkuInterior = styled.div`
	color: ${palette('blackAlpha80')};
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	width: 100%;
`;

export const TrialBadge = styled.div<{ $bgColor: keyof typeof CalmTheme.palette }>`
align-items: center;
    background: ${p => palette(p.$bgColor)};
    border-radius: 10px;
	display: flex;
    height: 30px;
    left: 24px;
    padding: 6px 12px;
    position: absolute;
    top: -18px;
    z-index: 2;
}
`;

export const PlanSkuLeft = styled.div``;

export const PlanSkuRight = styled.div`
	text-align: right;
`;

export const StrikethruPrice = styled.s`
	color: ${palette('gray5')};
`;
