import { defineMessages } from 'react-intl';

const messages = defineMessages({
	accountHeading: {
		id: 'subscriptionExtension.account.heading',
		defaultMessage: 'Get <blue>40% off</blue> your next year when you continue your subscription.',
		description: 'The heading of the login component',
	},
	accountSubHeading: {
		id: 'subscriptionExtension.account.subheading',
		defaultMessage: 'Log in to claim your offer.',
		description: 'The subheading of the login component',
	},
	accountFaqQuestion1: {
		id: 'subscriptionExtension.account.faq.question1',
		defaultMessage: 'I still have time before my access expires. What happens to that remaining time?',
		description: 'FAQ question about remaining subscription time',
	},
	accountFaqResponse1: {
		id: 'subscriptionExtension.account.faq.responseNew1',
		defaultMessage:
			"Your promotional year will automatically start after any remaining time you have on your active plan. That means you won't have any interruption in your access to Calm. For example: if you have two more weeks before your current Calm subscription expires, you will be charged in 2 weeks at your promotional rate.",
		description: 'FAQ response to remaining subscription time',
	},
	accountFaqQuestion2: {
		id: 'subscriptionExtension.account.faq.question2',
		defaultMessage: 'What happens after this promotional period?',
		description: 'FAQ question about post-promotional period',
	},
	accountFaqResponse2: {
		id: 'subscriptionExtension.account.faq.response2',
		defaultMessage:
			'After the promotional period, your plan will be renewed at the full price for 1 year of Calm Premium. You can cancel at any time to not be charged after the promotional period.',
		description: 'FAQ response about post-promotional period',
	},
	paymentValuePropHeader: {
		id: 'subscriptionExtension.payment.valueproplist.header',
		defaultMessage: 'Get <blue>40% off</blue> your next year when you continue your subscription.',
		description: 'Value props of calm with 40% off Calm Premium',
	},
	terms: {
		id: 'subscriptionExtension.payment.terms',
		defaultMessage:
			'When your plan renews on {expireDate}, you will be charged {price} for a 1-year subscription. After your promotional year, your plan will renew at {originalPrice}, billed annually. <cancelAnytimeLink>Cancel anytime</cancelAnytimeLink>.',
		description: 'A line to inform the user of the terms of their subscription extension',
	},
	offerTerms: {
		id: 'subscriptionExtension.payment.offerTerms',
		defaultMessage:
			'By clicking "Accept Offer", you agree to convert your remaining {premiumSubscriptionDaysRemaining} days of Calm Premium to a free trial that will renew at a promotional price of {price} on {expireDate}. Your plan will subsequently renew at {originalPrice} after the promotional period. <cancelAnytimeLink>Cancel anytime</cancelAnytimeLink>.',
		description: 'Terms for the susbscription extension offer',
	},
	paymentCta: {
		id: 'subscriptionExtension.payment.cta',
		defaultMessage: 'Accept Offer',
		description: 'CTA text for accepting the offer',
	},
	handoffHeading: {
		id: 'subscriptionExtension.handoff.heading',
		defaultMessage: "You're all set!",
		description: 'The heading of the handoff screen',
	},
	handoffSubHeading: {
		id: 'subscriptionExtension.handoff.subheading',
		defaultMessage:
			"Now your access to Calm will continue without interruption. Discover the full range of Calm's features and benefits on the official Calm app.",
		description: 'The subheading of the handoff screen',
	},
	ineligibleErrorHeading: {
		id: 'subscriptionExtension.error.ineligible.heading',
		defaultMessage: 'Welcome back to Calm!',
		description: 'Heading shown to user when they are ineliglible for the offer',
	},
	ineligibleErrorSubheading: {
		id: 'subscriptionExtension.error.ineligible.subheading',
		defaultMessage: "Unfortunately, you're not eligible for this offer.",
		description: 'Copy shown to user when they are ineliglible for the offer',
	},
	ineligibleErrorCta: {
		id: 'subscriptionExtension.error.ineligible.cta',
		defaultMessage: 'Back to Calm',
		description: 'Button copy that takes user to Calm application',
	},
});

export default messages;
