/* eslint-disable no-console */
import { AnyAction } from 'redux';

import { Prices, SET_PRICE, SET_PRICES } from './types';

export const initialState: Prices = {
	current: {
		monthly: 1399,
		yearly: 6999,
		quarterly: 3499,
		lifetime: 3999,
		family: 9999,
		currency: 'USD',
	},
	original: {
		monthly: 1399,
		quarterly: 3499,
		yearly: 6999,
		lifetime: 3999,
		family: 9999,
		currency: 'USD',
	},
	coupons: {},
	pricing_format: {
		format: '$%PRICE% %CURRENCY%',
		seperator: '.',
		currency: 'USD',
	},
};

export function pricesReducer(state = initialState, action: AnyAction = { type: undefined }): Prices {
	switch (action.type) {
		case SET_PRICES:
			return action.payload || initialState;
		case SET_PRICE:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
}
