import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { DTCOfferDetails } from '@/components/dtcPartnerships/shared/types';
import { useFormattedCurrency } from '@/hooks/purchase';
import { usePricesState } from '@/hooks/store';

import messages from './messages';

interface MessageMap {
	monthly: ReactNode;
	yearly: ReactNode;
	lifetime?: ReactNode;
	quarterly?: ReactNode;
	family?: ReactNode;
}

export function useGetPurchaseTerms(offer_details?: DTCOfferDetails): ReactNode {
	const prices = usePricesState();
	const { formatMessage } = useIntl();
	const formatCurrency = useFormattedCurrency();
	if (!offer_details) return <></>;

	function getCouponDuration(): number {
		if (offer_details?.duration_in_months) {
			if (offer_details.d2c_subscription_plan === 'monthly') {
				return offer_details.duration_in_months;
			}
			return Math.ceil((offer_details.duration_in_months as number) / 12);
		}
		return 1;
	}

	const translationOptions = {
		offerPrice: formatCurrency(prices.current[offer_details?.d2c_subscription_plan || 'yearly']),
		originalPrice: formatCurrency(prices.original[offer_details?.d2c_subscription_plan || 'yearly']),
		cancel: (
			<a
				href="https://support.calm.com/hc/en-us/articles/115002473607-How-to-turn-off-auto-renewal-or-cancel-my-subscription"
				target="_blank"
				rel="noreferrer"
			>
				{formatMessage(messages.cancelAnytime)}
			</a>
		),
		quantity: getCouponDuration(),
	};

	const trialOfferMessageKeyMap: MessageMap = {
		monthly: formatMessage(messages.purchaseTermsWithTrial_monthly, translationOptions),
		quarterly: formatMessage(messages.purchaseTermsWithTrial_quarterly, translationOptions),
		yearly: formatMessage(messages.purchaseTermsWithTrial_yearly, translationOptions),
		family: formatMessage(messages.purchaseTermsWithTrial_yearly, translationOptions),
	};

	const noTrialOfferMessageKeyMap: MessageMap = {
		monthly: formatMessage(messages.purchaseTermsWithoutTrial_monthly, translationOptions),
		quarterly: formatMessage(messages.purchaseTermsWithoutTrial_quarterly, translationOptions),
		yearly: formatMessage(messages.purchaseTermsWithoutTrial_yearly, translationOptions),
		family: formatMessage(messages.purchaseTermsWithoutTrial_yearly, translationOptions),
	};

	if (offer_details?.trial_duration) {
		return trialOfferMessageKeyMap[offer_details.d2c_subscription_plan || 'yearly'];
	}
	return noTrialOfferMessageKeyMap[offer_details?.d2c_subscription_plan || 'yearly'];
}
