export type Locale = 'en' | 'fr' | 'de' | 'es' | 'ja' | 'ko' | 'pt' | 'pl' | 'it' | 'zh';
export const locales = ['en', 'fr', 'de', 'es', 'ja', 'ko', 'pt', 'pl', 'it', 'zh'];

export const SET_LOCALE = 'SET_LOCALE';

interface SetLocaleAction {
	type: typeof SET_LOCALE;
	payload: Locale;
}

export type LocaleActionTypes = SetLocaleAction;
