import styled, { css } from 'styled-components';
import { ifProp, palette } from 'styled-tools';

import {
	Text,
	PaletteColor,
	minWidth,
	units,
	FontSizes,
	fontWeight,
	FontWeights,
	fontSize,
} from '@calm-web/design-system';

import CircleCheck from 'icons/circle-check.svg';

export const List = styled.div<{ hasTitle: boolean; isRevampRedempFlow?: boolean }>`
	border-top: ${p => (p.title ? `1px ${palette('gray2')} solid` : '')};
	color: ${palette('gray8')};
	padding-top: ${({ hasTitle, isRevampRedempFlow }) => (hasTitle && !isRevampRedempFlow ? `16px` : '')};
	margin: ${({ hasTitle, isRevampRedempFlow }) => (hasTitle && !isRevampRedempFlow ? `16px` : '')} auto 0;
`;

export const PlanTitle = styled(Text).attrs({
	el: 'h2',
	styleAs: 'h5',
	noMargin: true,
})`
	${minWidth('tablet')} {
		font-size: 22.5px;
	}
`;

export const Unordered = styled.ul<{
	gapSize?: string;
	isB2BAccountPage?: boolean;
}>`
	display: ${p => (p.isB2BAccountPage ? 'inline-block' : 'flex')};
	flex-wrap: wrap;
	flex-direction: column;
	padding: 0;
	gap: ${p => p.gapSize ?? ''};
	margin-top: ${p => p.gapSize ?? ''};
	li {
		list-style: none;
	}
`;

export const CheckIcon = styled(CircleCheck)<{ $color: string }>`
	flex: 0 0 24px;
	height: 24px;
	margin-right: 18px;
	position: relative;
	top: 3px;
	width: 24px;

	path {
		fill: ${p => palette(p.$color)};
	}
`;

export const CircleIcon = styled.div<{ $color: string }>`
	height: 22px;
	margin-right: 18px;
	position: relative;
	top: 3px;
	width: 22px;
	min-width: 22px;
	color: ${palette('white')};
	background-color: ${p => palette(p.$color)};
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const BulletPoint = styled.li<{
	$textColor?: PaletteColor;
}>`
	display: flex;
	align-items: flex-start;
	color: ${p => palette(p.$textColor ?? 'gray8')};
	margin-top: 8px;
	text-align: left;
	width: 100%;
`;

export const ValuePropTextContainer = styled.div<{
	propPadding: boolean;
}>`
	margin-bottom: ${ifProp('propPadding', '20px', 0)};
`;

export const ValuePropHeader = styled(Text)`
	margin-top: 0 !important;
	margin-bottom: 4px;
`;

export const ValuePropBody = styled(Text)`
	margin-top: 0;
	margin-block-start: 0 !important;
	margin-block-end: 0 !important;
`;

export const Title = styled(Text)<{
	isRevampRedempFlow?: boolean;
}>`
	${ifProp(
		'isRevampRedempFlow',
		css`
			margin-top: ${units(1.25)};
			margin-bottom: ${units(2)};

			${minWidth('desktop')} {
				margin-top: ${units(1.5)};
				margin-bottom: ${units(3.5)};
			}
		`,
	)}
`;

export const SubTitle = styled(Text).attrs({
	el: 'div',
})`
	color: ${palette('blackAlpha60')};
	${fontSize(FontSizes.lg)};
	${fontWeight(FontWeights.Medium)};
`;
