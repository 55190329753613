import { DateTime } from 'luxon';

import { useLocale } from '@/hooks/locale/useLocale';
import { useUserState } from '@/hooks/store';
import { SubscriptionKeys } from '@/store/user/types';

export function useSubscriptionDate(): {
	expireDate: string | null;
	extendDate: string | null;
	renewDate: string | null;
	premiumSubscriptionDaysRemaining: number | null;
} {
	const user = useUserState();
	const { calmLanguage } = useLocale();

	const expireDate = user?.subscription?.expires
		? DateTime.fromISO(user.subscription.expires, {
				locale: calmLanguage,
		  }).toLocaleString(DateTime.DATE_FULL)
		: null;

	const extendDate = DateTime.fromISO(DateTime.local().toString(), {
		locale: calmLanguage,
	})
		.plus({ year: 1 })
		.toLocaleString(DateTime.DATE_FULL);

	const detailsKey: SubscriptionKeys | undefined =
		user?.subscription?.type && `${user?.subscription?.type}_details`;
	const subscriptionDetails = detailsKey && user?.subscription?.[detailsKey];

	const renewDate =
		subscriptionDetails && 'renews_at' in subscriptionDetails
			? DateTime.fromISO(String(subscriptionDetails.renews_at), {
					locale: calmLanguage,
			  }).toLocaleString(DateTime.DATE_FULL)
			: null;

	const premiumSubscriptionDaysRemaining = expireDate
		? Math.ceil((new Date(expireDate).getTime() - new Date().getTime()) / (24 * 60 * 60 * 1000))
		: null;

	return { expireDate, extendDate, renewDate, premiumSubscriptionDaysRemaining };
}
