import { defineMessages } from 'react-intl';

export default defineMessages({
	firstName: {
		id: 'redesign.purchase.creditCardForm.nameOnCard',
		defaultMessage: 'Name on Card',
		description: 'CC Name on Card',
	},
	firstNameError: {
		id: 'generics.nameOnCardError',
		defaultMessage: 'Name on Card is required!',
		description: 'CC Name Required',
	},
	startFreeTrial: {
		id: 'redesign.purchase.creditCardForm.button.startFreeTrial2',
		defaultMessage: 'Start Your Trial',
		description: 'Default credit card form button text',
	},
	useCard: {
		id: 'redesign.purchase.creditCardForm.button.useCard',
		defaultMessage: 'Get Calm Premium',
		description: 'Button text for already subbed',
	},
	success: {
		id: 'redesign.purchase.creditCardForm.button.success',
		defaultMessage: 'Success',
		description: 'Button text for successful purchase',
	},
	secureTransaction: {
		id: 'creditCardForm.secureTransaction',
		defaultMessage: 'All transactions are secure and encrypted.',
		description: 'Text ensuring the user that their credit card will be processed securely',
	},
});
