import { User } from '@/store/user/types';

import { isTeams } from './app/msftTeams';
import { getAllCookies, removeCookie, setCookie } from './cookies';
import { futureDate } from './futureDate';
import { CALM_LANGUAGES } from './language';

export const ACCEPT_GDPR_COOKIES_KEY = 'has-agreed-to-cookies';
export const DECLINE_GDPR_COOKIES_KEY = 'has-declined-cookies';
export const CALM_LIMITED_DATA_USE = 'calm-limited-data-use';

const CALM_DOMAIN = 'calm.com';

export const CALM_AUTH_METHOD = 'calm-auth-method';
export const CalmAuthMethods = {
	Email: 'email',
	Apple: 'apple',
	Facebook: 'facebook',
	Google: 'google',
} as const;
export type CalmAuthMethods = (typeof CalmAuthMethods)[keyof typeof CalmAuthMethods];

export const CALM_ID = 'calm-id';
export const CALM_DEVICE_ID = 'x-device-id';
export const CALM_TEAMS_DEVICE_ID = 'msft-teams-device-id';
export const CALM_USER_ID = 'calm-user-id';
export const CALM_USER_TOKEN = 'calm-user-token';
export const CALM_IS_HIPAA_USER = 'calm-is-hipaa-user';

export const CALM_USER_COOKIE_KEYS = [
	CALM_ID,
	CALM_DEVICE_ID,
	CALM_USER_ID,
	CALM_USER_TOKEN,
	CALM_IS_HIPAA_USER,
];

const cookieDomains = [
	'',
	'.local.calm.com',
	'local.calm.com',
	'.calm.com',
	CALM_DOMAIN,
	'www.calm.com',
	'.www.calm.com',
	'.googleadservices.com',
	'.ranksci.com',
	'.app.link',
	'.youtube.com',
	'.google.com',
];

// When running MSFT Teams, our cookies are used on the MS domain rather than our own domain.
// This means that we need to be more permissive with the "sameSite" settings.
export const getTeamsCookieOptions = (): {
	sameSite?: 'strict' | 'Strict' | 'lax' | 'Lax' | 'none' | 'None';
	secure: boolean;
} => {
	return { sameSite: 'none', secure: true };
};

export const acceptGdprCookies = (): void => {
	if (isTeams()) {
		setCookie(ACCEPT_GDPR_COOKIES_KEY, 'true', {
			expires: futureDate(10, 'years'),
			...getTeamsCookieOptions(),
		});
		removeCookie(DECLINE_GDPR_COOKIES_KEY, getTeamsCookieOptions());
		removeCookie(CALM_LIMITED_DATA_USE, { domain: CALM_DOMAIN, ...getTeamsCookieOptions() });
		return;
	}
	setCookie(ACCEPT_GDPR_COOKIES_KEY, 'true', {
		expires: futureDate(10, 'years'),
	});
	removeCookie(DECLINE_GDPR_COOKIES_KEY);
	removeCookie(CALM_LIMITED_DATA_USE, { domain: CALM_DOMAIN });
};

export const declineGdprCookies = (): void => {
	if (isTeams()) {
		setCookie(ACCEPT_GDPR_COOKIES_KEY, 'false', {
			expires: futureDate(10, 'years'),
			...getTeamsCookieOptions(),
		});
		setCookie(DECLINE_GDPR_COOKIES_KEY, 'true', {
			expires: futureDate(10, 'years'),
			...getTeamsCookieOptions(),
		});
		removeCookie(CALM_LIMITED_DATA_USE, { domain: CALM_DOMAIN, ...getTeamsCookieOptions() });
		return;
	}
	setCookie(ACCEPT_GDPR_COOKIES_KEY, 'false', {
		expires: futureDate(10, 'years'),
	});
	setCookie(DECLINE_GDPR_COOKIES_KEY, 'true', {
		expires: futureDate(10, 'years'),
	});
	removeCookie(CALM_LIMITED_DATA_USE, { domain: CALM_DOMAIN }); // We want to have either limited data or GDPR cookies, not both
};

export const declineOptInCookies = (): void => {
	if (isTeams()) {
		setCookie(CALM_LIMITED_DATA_USE, 'true', {
			expires: futureDate(10, 'years'),
			domain: CALM_DOMAIN,
			...getTeamsCookieOptions(),
		});
		removeCookie(DECLINE_GDPR_COOKIES_KEY, getTeamsCookieOptions());
		removeCookie(ACCEPT_GDPR_COOKIES_KEY, getTeamsCookieOptions());
		return;
	}
	setCookie(CALM_LIMITED_DATA_USE, 'true', {
		expires: futureDate(10, 'years'),
		domain: CALM_DOMAIN,
	});
	removeCookie(DECLINE_GDPR_COOKIES_KEY);
	removeCookie(ACCEPT_GDPR_COOKIES_KEY);
};

export const acceptOptInCookies = (): void => {
	if (isTeams()) {
		removeCookie(CALM_LIMITED_DATA_USE, { domain: CALM_DOMAIN, ...getTeamsCookieOptions() });
		removeCookie(DECLINE_GDPR_COOKIES_KEY, getTeamsCookieOptions());
		removeCookie(ACCEPT_GDPR_COOKIES_KEY, getTeamsCookieOptions());
		return;
	}
	removeCookie(CALM_LIMITED_DATA_USE, { domain: CALM_DOMAIN });
	removeCookie(DECLINE_GDPR_COOKIES_KEY);
	removeCookie(ACCEPT_GDPR_COOKIES_KEY);
};

export const setUserCookies = (user: User, authMethod?: CalmAuthMethods): void => {
	setCookie(CALM_USER_TOKEN, user.token as string);
	setCookie(CALM_USER_ID, user.id as string);
	setCookie(CALM_ID, user.calm_identifier as string);
	if (user?.is_hipaa_compliant) {
		setCookie(CALM_IS_HIPAA_USER, String(user.is_hipaa_compliant));
	}
	if (user?.has_opted_in_limited_data_use) {
		setCookie(CALM_LIMITED_DATA_USE, String(user.has_opted_in_limited_data_use), { domain: CALM_DOMAIN });
	}
	if (authMethod) {
		setCookie(CALM_AUTH_METHOD, String(authMethod), { expires: 1 });
	}
};

export const setTeamsUserCookies = (user: User, authMethod?: CalmAuthMethods): void => {
	setCookie(CALM_USER_TOKEN, user.token as string, getTeamsCookieOptions());
	setCookie(CALM_USER_ID, user.id as string, getTeamsCookieOptions());
	setCookie(CALM_ID, user.calm_identifier as string, getTeamsCookieOptions());
	if (user?.is_hipaa_compliant) {
		setCookie(CALM_IS_HIPAA_USER, String(user.is_hipaa_compliant), getTeamsCookieOptions());
	}
	if (user?.has_opted_in_limited_data_use) {
		setCookie(CALM_LIMITED_DATA_USE, String(user.has_opted_in_limited_data_use), {
			...getTeamsCookieOptions(),
			domain: CALM_DOMAIN,
		});
	}
	if (authMethod) {
		setCookie(CALM_AUTH_METHOD, String(authMethod), { ...getTeamsCookieOptions(), expires: 1 });
	}
};

export const removeAllCookies = (willKeepLoginCookies = true): void => {
	Object.keys(getAllCookies() || {}).forEach(cookie => {
		// Only remove cookies if we don't want to keep login cookies or if they aren't login cookies
		if (!(willKeepLoginCookies && CALM_USER_COOKIE_KEYS.includes(cookie))) {
			cookieDomains.forEach(domain => {
				removeCookie(cookie, { domain });
			});
		}
	});
};

// We need to pass in "sameSite" to removeCookie call to actually remove the cookie
export const removeAllTeamsUserCookies = (): void => {
	removeCookie(CALM_USER_TOKEN, getTeamsCookieOptions());
	removeCookie(CALM_USER_ID, getTeamsCookieOptions());
	removeCookie(CALM_ID, getTeamsCookieOptions());
	removeCookie(CALM_IS_HIPAA_USER, getTeamsCookieOptions());
};

export const isHomePageCookies = (pathName: string) => {
	const path = CALM_LANGUAGES.map(lang => {
		return lang === 'en' ? `/` : `/${lang}`;
	});

	return path.includes(pathName);
};
