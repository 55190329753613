import styled, { css } from 'styled-components';
import { palette } from 'styled-tools';

import { fontSize, FontSizes, fontWeight, FontWeights, Text } from '@calm-web/design-system';

const orderRow = css`
	color: ${palette('gray8')};
	display: flex;
	${fontSize(FontSizes.base)};
	${fontWeight(FontWeights.Regular)};
	line-height: 1.5;
	margin-bottom: 8px;
	justify-content: space-between;
`;

export const OrderRow = styled.div`
	${orderRow}
`;

export const OrderRowLight = styled.div`
	${orderRow}
	&:last-child {
		align-self: flex-end;
	}
`;

export const OrderRowBold = styled.div`
	${orderRow}
	${fontWeight(FontWeights.Demi)};
`;

export const OrderRowSmall = styled.div<{ $isSecondFreeTrial?: boolean }>`
	${orderRow}
	color: ${palette('gray8')};
	font-weight: ${p => (p.$isSecondFreeTrial ? FontWeights.Regular : FontWeights.Demi)}
		${fontWeight(FontWeights.Demi)};
	font-size: 13.5px;
	margin-top: 16px;
`;

export const OrderRowSmallLight = styled(Text)`
	${orderRow}
	margin-top: 16px;
	margin-bottom: 18px;
`;

export const Divider = styled.div`
	background: #c6c6c6;
	height: 1px;
	margin: 1rem 0;
	width: 100%;
`;

export const Wrapper = styled.div`
	${OrderRow}, ${OrderRowLight}, ${OrderRowBold}, ${OrderRowSmall} {
		color: ${p => (p.theme.isDark ? palette('white') : 'inherit')};
	}
`;

export const OfferHighlight = styled.div`
	color: ${palette('blue3')};
`;
export const Capitalize = styled.div`
	text-transform: capitalize;
`;
