import styled from 'styled-components';

import { units } from '@calm-web/design-system';

export const Wrapper = styled.div`
	padding: ${units(5)};
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: ${units(5)};
`;

export const Copy = styled.div`
	text-align: center;
`;
