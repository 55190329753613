import styled from 'styled-components';

import { minWidth } from '@calm-web/design-system';

export const Wrapper = styled.ul`
	margin-top: 32px;
	margin-bottom: 8px;
	list-style: none;
	padding-left: 0;

	${minWidth('desktop')} {
		grid-column-gap: 24px;
		margin-bottom: 28px;
	}
`;

export const PlanSkuListItem = styled.li`
	width: 100%;
`;
