import { useUserState } from '@/hooks/store';
import useQueryParams from '@/hooks/utils/useQueryParams';

export function useFreeTrial() {
	const user = useUserState();
	const query = useQueryParams();

	const isSecondFreeTrial = query?.focus === 'second_free_trial';

	if (user?.subscription?.valid) {
		return false;
	}
	if (isSecondFreeTrial) {
		return true;
	}
	if (user?.subscription?.has_ever_done_free_trial) {
		return false;
	}
	return true;
}
